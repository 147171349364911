<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />

    <a-col v-else class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>

        <button
          v-show="form.is_static === true"
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>

        <button
          v-show="form.is_static === true"
          class="tab-btn"
          :class="{ active: tab === 'document' }"
          @click="tab = 'document'"
        >
          <a-icon type="file" />Документы
        </button>
      </a-row>

      <div v-show="tab === 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                Перевести копию на латиницу
              </a-button>
              <a-button v-if="langTab === 'uz'" @click.stop="translitAction">
                Перевести копию на кирилицу
              </a-button>
            </a-row>
          </a-col>
        </a-row>

        <!-- route name -->
        <a-row
          :class="{ 'form-group--error': titlesCheck }"
          align="middle"
          class="flex-baseline input-form__group"
        >
          <a-col :span="4" class="form_title">
            {{ $t("MenuTitle") }}
          </a-col>
          <a-col :span="12">
            <a-input
              v-model="$v.form.title[langTab].$model"
              :placeholder="$t('MenuTitlePlace')"
              class="form_title-input"
            />
            <div v-if="!$v.form.title.oz.required" class="error-text">
              Введите название на кирилский узбекский.
            </div>
            <div v-if="!$v.form.title.uz.required" class="error-text">
              Введите название на латинский узбекский.
            </div>
            <!-- <div v-if="!$v.form.title.ru.required" class="error-text">
              Введите название на русском.
            </div> -->
          </a-col>
        </a-row>

        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuGroup") }}
          </a-col>
          <a-col :span="12">
            <a-input v-model="form.group" :placeholder="$t('MenuGroupPlace')" />
          </a-col>
        </a-row>

        <!-- position -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title"> {{ $t("Url") }}: </a-col>
          <a-col :span="12">
            <a-input
              v-model="form.url"
              placeholder="Введите url (для внешних ссылок)"
            />
          </a-col>
        </a-row>

        <!-- menu list to pick parent menu -->
        <a-row
          v-if="!(form && form.id && form.parent_id === null)"
          class="sm-mb"
        >
          <a-col :span="4" class="form_title">
            {{ $t("MenuParentName") }}
          </a-col>
          <a-col :span="12">
            <a-select
              v-model="form.parent"
              :filter-option="filterOption"
              option-filter-prop="children"
              :placeholder="$t('MenuParentName')"
              :allow-clear="true"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="menu in menuList"
                :key="menu.id"
                :value="menu.id"
              >
                (ID: {{ menu.id }}) {{ menu.title || "-" }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <!-- position -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuPosition") }}:
          </a-col>
          <a-col :span="12">
            <a-input-number
              v-model="form.position"
              placeholder="Введите позицию"
            />
            <div v-if="!form.position" class="error-text">Введите позицию</div>
          </a-col>
        </a-row>

        <!-- is_blog toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title">{{ $t("MenIsBlog") }}: </a-col>
          <a-switch v-model="form.is_blog" />
        </a-row>

        <!-- static toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title">{{ $t("IsStatic") }}: </a-col>
          <a-switch v-model="form.is_static" />
        </a-row>

        <!-- active toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("Active") }}: </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>

        <!-- has_category toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("HasCategory") }}: </a-col>
          <a-switch v-model="form.has_category" />
        </a-row>

        <a-row
          v-if="form.is_static"
          :class="{ 'form-group--error': emptyContent }"
          style="margin-top: 25px"
          type="flex"
        >
          <a-col :span="4" class="form_title"> {{ $t("Content") }}: </a-col>
          <a-col v-show="langTab === 'oz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.oz"
              :initial-value="form.content.oz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'uz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.uz"
              :initial-value="form.content.uz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'qr'" :span="20">
            <editor
              :value="form.content.qr"
              :initial-value="form.content.qr"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.qr = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab === 'ru'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.ru"
              :initial-value="form.content.ru"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'en'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.en"
              :initial-value="form.content.en"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
        </a-row>
      </div>

      <!-- store image modal -->
      <a-modal
        :visible="modalVisible"
        title="Хранилище изображений"
        :closable="false"
        style="max-height: 700px; max-width: 900px"
        width="80%"
      >
        <imageStorage
          @pasteHandler="editorInstance.insertContent(arguments[0])"
        />
        <template slot="footer">
          <a-button key="back" @click="modalVisible = false">
            Закрыть
          </a-button>
        </template>
      </a-modal>

      <!-- photo tab -->
      <div v-show="tab === 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col
            :span="4"
            style="padding-top: 4px; text-align: right; padding-right: 8px"
          >
            Изображения
          </a-col>

          <a-col :span="12">
            <image-upload
              :list="photoList"
              accept="image/jpeg, image/png"
              multiple
              upload-multiple
              :with-cropper="false"
              has-method
              @change="handleImages"
              @drag-update="updateFilesPosition"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>

      <!-- document tab  -->
      <div v-show="tab === 'document' && form.is_static === true">
        <h4 v-if="pending">Идет загрузка...</h4>
        <span v-show="!pending">
          <a-row style="margin-bottom: 15px; margin-top: 15px">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>

            <a-col :span="12">
              <a-button type="primary" @click="$refs.documentRef.click()">
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />
                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
        </span>
      </div>

      <span v-if="form.id" slot="tabBarExtraContent">
        <a-popconfirm
          placement="bottomRight"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem"
        >
          <a-button
            :disabled="pending"
            style="margin-right: 10px"
            type="danger"
          >
            <a-icon type="delete" />{{ $t("Delete") }}
          </a-button>
        </a-popconfirm>
      </span>

      <a-row
        v-if="tab === 'content'"
        class="form-buttons__action"
        type="flex"
        style="margin-top: 20px"
      >
        <a-col :span="4" class="form_title" />

        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitMenu((saveback = false))"
          >
            <a-icon type="check" />
            {{ form.id ? $t("Save") : $t("Add") }}
          </a-button>

          <a-button
            v-if="form.id"
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitMenu((saveback = true))"
          >
            <a-icon type="check" />
            {{ $t("SaveAndExit") }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import spoiler from "@/mixins/spoiler.js"
import { required } from "vuelidate/lib/validators"
import cloneDeep from "lodash/cloneDeep"
import InputFileItem from "@/components/utils/input-file-item"
const def = () => ({
  status: true,
  position: 1,
  url: "",
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  language_support: {
    uz: false,
    oz: false,
    ru: false,
    en: false
  },
  children: null,
  group: "",
  parent: null,
  is_active: false,
  is_static: false,
  is_blog: false,
  has_category: false
})
export default {
  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    editor: () => import("@tinymce/tinymce-vue"),
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    InputFileItem
  },
  mixins: [spoiler],
  data: () => ({
    langTab: $langPrefix,
    loading: false,
    pending: false,
    imageUploading: false,
    form: { ...def },
    submitStatus: "",
    emptyContent: false,
    modalVisible: false,
    document_files_array: [],
    menuList: [],
    images: [],
    tab: "content"
  }),
  computed: {
    titlesCheck() {
      return !!(
        (this.$v.form.title.oz.$error || this.$v.form.title.uz.$error)
        // this.$v.form.title.ru.$error
      )
    },
    isContentNull() {
      return !!(
        !this.form.content.uz &&
        !this.form.content.oz &&
        // !this.form.content.ru &&
        !this.form.content.en
      )
    },
    photoList() {
      return this.form && this.form.images
    },
    cList() {
      return (
        this.menuList.filter((item) => {
          if (this.form && this.form.id) {
            if (item.id !== this.form.id) {
              return item
            }
          }
          return item
        }) || []
      )
    },
    cMenuList() {
      return (
        (this.cList &&
          this.cList
            .slice()
            .sort((a, b) => a.position - b.position)
            .map((item) => ({
              ...item,
              title: {
                ...((item.title && JSON.parse(item.title)) || {})
              },
              children:
                item.children &&
                item.children
                  .map((c) => ({
                    ...c,
                    title: {
                      ...(c.title && JSON.parse(c.title))
                    }
                  }))
                  .sort((a, b) => a.position - b.position)
            }))) ||
        []
      )
    },
    getKey() {
      return this.langTab
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    },
    form: {
      handler() {
        if (
          !this.form.id &&
          this.form.parent_id &&
          this.$route.name !== "menu-detail"
        ) {
          const f = this.menuList.findIndex(
            (item) => item.id === this.form.parent_id
          )
          if (
            f !== -1 &&
            this.menuList[f].children &&
            this.menuList[f].children.length
          ) {
            const childrenMenuLen = this.menuList[f].children.length
            this.form.position = childrenMenuLen + 1
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.toDef()
  },
  beforeDestroy() {
    this.toDef()
  },
  validations: {
    form: {
      title: {
        uz: {
          required
        },
        oz: {
          required
        },
        ru: {
          // required
        },
        en: {
          // required
        },
        qr: {
          // required
        }
      }
    }
  },
  methods: {
    openStoreModal() {
      this.modalVisible = true
    },
    toDef() {
      this.$set(this, "form", {
        ...def()
      })
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from])
        }
        // turn on translit target language_support
        // console.log(this.form.language_support, to, true)
        this.$set(this.form.language_support, to, true)
        this.$message.success("Успешно переведено")
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    async fetchMenuList() {
      this.loading = true
      try {
        /*const data = await this.$store.dispatch("menu/fetch", {
          parent: true
        })*/
        const data = await this.$store.dispatch("menu/fetch")
        this.menuList = data || []
      } catch (e) {
        console.log(e)
        this.loading = false
      }
      this.loading = false
    },
    async fetchData() {
      await this.fetchMenuList()
      if (!this.$route.params.id || isNaN(this.$route.params.id)) {
        // console.log("Invalid params");
        return
      }
      this.loading = true
      try {
        const res = await this.$store.dispatch(
          "menu/detail",
          this.$route.params.id
        )
        if (res) {
          this.$set(this, "form", {
            ...res,
            title: {
              ...this.langKeys,
              ru: res.title_ru,
              uz: res.title_uz,
              oz: res.title_oz,
              en: res.title_en,
              qr: res.title_qr
            },
            content: {
              ...this.langKeys,
              ru: res.content_ru,
              uz: res.content_uz,
              oz: res.content_oz,
              en: res.content_en,
              qr: res.content_qr
            },
            position: +res.position
          })
          this.form.attachments.forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2)
              }
            ]
          })
          // this.document_files_array = res.attachments
          // console.log(this.document_files_array)
        }

        // console.log(res);
      } catch (err) {
        console.log(err)
        this.loading = false
        this.$sentry.captureMessage(err)
      }

      this.loading = false
    },
    async submitMenu(saveback) {
      // console.log(this.form)
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR"
        return
      }
      // do your submit logic here
      this.pending = true
      try {
        let newData = cloneDeep(this.form)
        delete newData.attachments
        delete newData.images

        newData = {
          ...newData,
          content_ru: newData.content.ru,
          content_uz: newData.content.uz,
          content_oz: newData.content.oz,
          content_en: newData.content.en,
          content_qr: newData.content.qr,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr
        }

        delete newData.content
        delete newData.title
        delete newData.slug

        if (newData.is_static === false) {
          delete newData.content_qr
          delete newData.content_ru
          delete newData.content_uz
          delete newData.content_oz
          delete newData.content_en
        }

        if (
          newData.parent === null ||
          newData.parent === "" ||
          newData.parent === undefined
        ) {
          // delete newData.parent
          newData.parent = ""
        }
        if (newData.group === null || newData.parent === "") {
          // delete newData.group
          newData.parent = ""
        }

        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          if (newData[key] === null) {
            newData[key] = ""
          }
          f.append(key, newData[key])
          // key !== "images" && f.append(key, newData[key])
        })

        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/menu/" + this.form.id + "/update/"
              : "/admin/menu/create/",
          method: this.form && this.form.id ? "patch" : "post",
          data: f
        }

        await this.$api[createOrUpdate.method](
          createOrUpdate.url,
          createOrUpdate.data
        ).then((res) => {
          if (res && res.status >= 200 && res.data && res.data.id) {
            if (saveback) {
              this.$router.push({
                name: "menu"
              })
            }
            this.$message.success(
              `Меню успешно ${this.form.id ? "обновлен" : "создан"}`
            )
            if (res.data.id) {
              const { id } = res.data
              this.form = { ...this.form, id }
              return true
            }
          }
        })
      } catch (e) {
        console.log(e)
        this.pending = false
      }

      this.pending = false
    },
    async removeItem() {
      try {
        const r = await this.$store.dispatch(
          "menu/removeItemAction",
          this.form.id
        )

        if (r) {
          this.$message.success("Меню успешно удален")
          this.$router.go(-1)
        }
      } catch (err) {
        console.log(err)
      }
    },
    updateFilesPosition(files) {
      let promises = []
      // let otherImages = new FormData()
      let images_order = []
      // const mainImage = this.fileList.find(
      //   (item) => item.id && item.uzid === this.main_image
      // )
      Array.from(files)
        .filter((item) => item.id && item.uzid !== this.main_image)
        .map((item, index) => {
          // otherImages = new FormData()

          // otherImages.append("is_main", false)
          // otherImages.append("order", index + 1 )
          // otherImages.append("file_type", 0)
          // otherImages.append("image_id", item.id || "untitled_file")
          // otherImages.append("file", item.file || "untitled_file")
          // console.log("item", item)
          // console.log("index", index)
          images_order = [
            ...images_order,
            { image_id: item.id, order: index + 1 }
          ]
        })
      promises.push(
        this.$api.patch("/admin/menu/image/order/update/", { images_order })
      )

      Promise.all(promises)
        .then(
          () => {},
          (err) => {
            console.log(err)
          }
        )
        .then(() => {
          this.$message.success("Позиции успешно сохранены")
        })
    },
    async handleDocumentInput(e) {
      if (this.form.id) {
        if (e.target.files && e.target.files !== 0) {
          let formImage = {
            menu: this.$route.params.id
          }
          let f = new FormData()
          Object.keys(formImage).forEach((k) => {
            f.append(k, formImage[k])
          })
          Array.from(e.target.files).forEach((item) => {
            f.append("file", item)
          })
          let res = await this.$api.post("/admin/menu/file/upload/", f)
          if (res && res.data) {
            res.data.attachments.forEach((item) => {
              this.document_files_array = [
                ...this.document_files_array,
                {
                  binary_file: item,
                  uid: Math.random().toString().slice(2)
                }
              ]
            })
          }
        }
      } else {
        if (e.target.files && e.target.files !== 0) {
          Array.from(e.target.files).forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2)
              }
            ]
          })
          console.log(this.document_files_array)
        }
      }

      this.$refs.documentRef.value = null
    },
    async deleteImage(val) {
      if (this.form.id) {
        await this.$api.delete("/admin/menu/image/" + val + "/delete/")
      } else {
        this.form.images = null
      }
    },
    async handleImages(arg) {
      if (this.form.id) {
        this.fileList = arg
        let formImage = {
          menu: this.$route.params.id
        }
        let newImages = arg.filter((item) => item && !item.id)
        let f = new FormData()
        Object.keys(formImage).forEach((k) => {
          f.append(k, formImage[k])
        })
        newImages.forEach((item) => {
          f.append(
            "file",
            item && item.fileTarget,
            item && item.fileTarget && item.fileTarget.name
          )
        })
        let res = await this.$api.post("/admin/menu/image/upload/", f)
        if (res && res.data) {
          this.images = res.data.images
        }
      } else {
        this.images.push(arg[0])
        console.log(this.images, arg[0])
      }
    },
    async deleteDocumentFileFromState(arg) {
      if (this.form.id) {
        try {
          const res = await this.$api.delete(
            "/admin/menu/file/" + arg.binary_file.id + "/delete/"
          )

          if (res) {
            const f = this.document_files_array.findIndex(
              (item) => item.uid === arg.uid
            )

            if (f !== -1) {
              this.document_files_array = [
                ...this.document_files_array.slice(0, f),
                ...this.document_files_array.slice(f + 1)
              ]
            }
          }
        } catch (err) {
          this.$sentry.captureMessage(err)
        }
      } else {
        this.document_files_array = this.document_files_array.filter(
          (el) => el.uid !== arg.uid
        )
      }
    }
  }
}
</script>
